import 'htmx.org'

import 'preline'

import Alpine from 'alpinejs'

// Add Alpine instance to window object.
window.Alpine = Alpine

// Start Alpine.
Alpine.start()

window.HSStaticMethods.autoInit()
